import React from 'react';

function AboutUs() {
  return (
    <div className="container mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900 mb-8">
          About <span className="text-indigo-600">RAGStream</span>
        </h1>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
          Use RAGStream for personalizing LLM responses. It makes it easy to create and deploy personalized AI apps. At its core, RAGStream follows the design principle of being <em>"Conventional but Configurable"</em>.
        </p>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
        </p>
      </div>
      <div className="mt-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Feature 1 */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="bg-indigo-500 rounded-full w-12 h-12 flex items-center justify-center text-white mb-4">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
              </svg>
            </div>
            <h2 className="text-xl font-semibold mb-2">Personalized AI</h2>
            <p className="text-gray-600">
              RAGStream enables the creation and deployment of personalized AI applications tailored to your data.
            </p>
          </div>

          {/* Feature 2 */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="bg-indigo-500 rounded-full w-12 h-12 flex items-center justify-center text-white mb-4">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <h2 className="text-xl font-semibold mb-2">Efficient Data Management</h2>
            <p className="text-gray-600">
              RAGStream efficiently handles unstructured data, segmenting it into manageable chunks and generating relevant embeddings for optimized retrieval.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div className="bg-indigo-500 rounded-full w-12 h-12 flex items-center justify-center text-white mb-4">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
              </svg>
            </div>
            <h2 className="text-xl font-semibold mb-2">Diverse APIs</h2>
            <p className="text-gray-600">
              RAGStream provides a suite of APIs for extracting contextual information, finding precise answers, and engaging in interactive chat conversations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;