import React from 'react';
import { ReactComponent as HomeSVG } from '../assets/Home.svg'; // Import the SVG file
import { ReactComponent as AboutSVG } from '../assets/About.svg'; // Import the second SVG file
import { ReactComponent as UserAboutSVG } from '../assets/3.svg'; // Import the third SVG file
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to another page
    navigate('/how-it-works');
  };

  return (
    <div className="relative min-h-screen overflow-y-auto">
      <div className="flex flex-col items-center">
        <div className="flex-1"></div> {/* Fill the space above the SVGs */}
        <div className="relative w-full h-full">
          <HomeSVG className="w-full h-full" />
          <button 
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2 mb-52 text-blue-500 border-none px-4 py-2 rounded-lg shadow-md"
            onClick={handleClick}
          >
            
          </button>
        </div>
        <UserAboutSVG className="w-full h-full" />
        <AboutSVG className="w-full h-full" />
        <div className="flex-1"></div> {/* Fill the space below the SVGs */}
      </div>
    </div>
  );
}

export default HomePage;
