import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../configs/config';

const SingleResource = () => {
  const { resourceID } = useParams(); // Access the resourceId from route params
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  console.log(resourceID)

  useEffect(() => {
    const fetchPdf = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/resources/get_pdf/${resourceID}`);
        if (!response.ok) {
          throw new Error('Failed to fetch PDF file');
        }
        const pdfBlob = await response.blob();
        const pdfObjectUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfObjectUrl);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPdf();

    // Cleanup function to revoke object URL
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [resourceID]);

  return (
    <div>
      {loading ? (
        <p>Loading PDF...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <iframe title="PDF Viewer" src={pdfUrl} width="100%" height="600px" />
      )}
    </div>
  );
};

export default SingleResource;
