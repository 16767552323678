import React, { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { API_BASE_URL } from '../configs/config';



function ResetPassword() {

  const navigate = useNavigate();

  const location = useLocation();

  const [password, setPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const [successMessage, setSuccessMessage] = useState('');



  const handleResetPassword = async (e) => {

    e.preventDefault();



    if (password !== confirmPassword) {

      setErrorMessage('Passwords do not match');

      return;

    }



    const searchParams = new URLSearchParams(location.search);

    const reset_token = searchParams.get('token');



    const requestData = {

      new_password: password,

      reset_token: reset_token,

    };



    try {

      const response = await fetch(`${API_BASE_URL}/auth/set_new_password`, {

        method: 'POST',

        headers: {

          'Content-Type': 'application/json',

        },

        body: JSON.stringify(requestData),

      });



      const responseData = await response.json();



      if (response.status === 200) {

        // Password reset successful

        setSuccessMessage(responseData.message);

        setErrorMessage('');

        //Navigate back to loging page
          // Navigate to the login page after a short delay
        setTimeout(() => {
          navigate('/login'); // Adjust '/login' to your actual login route
        }, 2000); // 2-second delay for user to see the success message

      } else {

        // Password reset failed

        setErrorMessage(responseData.message);

        setSuccessMessage('');

      }

    } catch (error) {

      console.error('Error resetting password:', error);

      setErrorMessage('An error occurred while resetting the password. Please try again later.');

      setSuccessMessage('');

    }

  };

  
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleResetPassword}>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                New Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <div className="mt-1">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Reset Password
              </button>
            </div>

            {successMessage && <div className="text-green-600 text-sm">{successMessage}</div>}
            {errorMessage && <div className="text-red-600 text-sm">{errorMessage}</div>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;