import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from '../configs/config';
import { Link } from 'react-router-dom';

function DeleteProjects() {
  const [projects, setProjects] = useState([]);
  const [showMenu, setShowMenu] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDeleting, setIsDeleting] = useState(false); // State to track delete process
  const userId = useSelector(state => state.userId);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/project/get_by_user/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch projects');
        }
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    if (userId) {
      fetchProjects();
    }
  }, [userId]);

  const refreshProjects = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/project/get_by_user/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch projects');
      }
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handleDeleteConfirmation = async (projectId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this project?');
    if (confirmDelete) {
      await deleteProject(projectId);
    }
  };

  const deleteProject = async (projectId) => {
    try {
      setIsDeleting(true); // Start spinner

      console.log('I am about to delete');
      console.log(`${API_BASE_URL}/project/delete/${projectId}`);
      const response = await fetch(`${API_BASE_URL}/project/delete/${projectId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          UserID: userId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to delete project');
      }

      window.location.reload();

    } catch (error) {
      console.error('Error deleting project:', error);
    } finally {
      setIsDeleting(false);
       // Stop spinner

    }
  };

  const handleMenuClick = (projectId) => {
    setShowMenu(projectId === showMenu ? null : projectId);
  };

  const handleDeleteProject = (projectId) => {
    setSelectedProjectId(projectId);
    handleDeleteConfirmation(projectId);
  };

  const filteredProjects = projects.filter(project =>
    project.ProjectName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="max-w-4xl mx-auto py-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Your Projects</h2>

      {/* Search Bar */}
      <div className="mb-6 relative">
        <input
          type="text"
          placeholder="Search projects..."
          className="bg-gray-200 border-2 border-gray-300 focus:outline-none focus:border-blue-500 rounded-lg px-4 py-2 w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <svg
          className="absolute right-3 top-3 h-5 w-5 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      </div>

      {/* Projects Grid */}
      <div className="grid md:grid-cols-1 gap-8">
        {filteredProjects.map(project => (
          <div key={project.ProjectID} className="relative bg-white shadow-xl rounded-lg overflow-hidden transition duration-500 hover:shadow-2xl">
            <div className="p-6" onClick={() => handleMenuClick(project.ProjectID)}>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <div className="h-10 w-10 rounded-full bg-green-500 mr-3 flex items-center justify-center">
                    <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">{project.ProjectName}</h3>
                </div>
                <button
                  className="p-2 text-gray-600 hover:text-gray-800 focus:outline-none transition duration-300 transform hover:scale-110"
                >
                  <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v.01M12 12v.01M12 18v.01"></path></svg>
                </button>
              </div>
              <p className="text-gray-700 my-4">{project.Description}</p>
              <p className="text-gray-600 text-sm">Creation Date: {project.CreationDate}</p>
            </div>
            {showMenu === project.ProjectID && (
              <div className="absolute top-0 right-0 mt-12 mr-4 z-50 w-48 bg-white border rounded shadow-lg">
                <ul className="divide-y divide-gray-100">
                  <li>
                    <button
                      className="flex items-center p-4 w-full text-left text-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-100 text-red-600"
                      onClick={() => handleDeleteProject(project.ProjectID)}
                    >
                      <svg className="w-6 h-6 mr-3" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                      Delete
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>

      {isDeleting && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

    </div>
  );
}

export default DeleteProjects;
