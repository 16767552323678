import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Resources from './Resources';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from '../configs/config';
import { useNavigate } from 'react-router-dom';

function ProjectPage() {
  const { projectId } = useParams();
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false); // State to control message visibility
  const { isLoggedIn, userId } = useSelector(state => state);
  const navigate = useNavigate(); // Hook for navigation

  const handleVectorizeResource = () => {
    setLoading(true);
    setShowMessage(true); // Show message when vectorizing starts

    // API endpoint for vectorizing resources
    const url = `${API_BASE_URL}/vectorize/add_resource`;
    
    // Data to be sent in the request body
    const data = {
      user_id: userId,
      project_id: projectId
    };

    // Configuring the fetch request
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if needed
      },
      body: JSON.stringify(data)
    };

    // Performing the API call
    fetch(url, requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to vectorize resource');
        }
        // Handle success response
        console.log('Resource vectorized successfully');
        setLoading(false);

        // Refresh the page after 5 seconds
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch(error => {
        // Handle error
        console.error('Error vectorizing resource:', error);
        setLoading(false);
      });
  };

  const handleOpenChatbox = () => {
    // Navigate to Chatbox page with projectId and userId as URL parameters
    navigate(`/chat/${projectId}/${userId}`);
  };

  return (
    <div className="max-w-4xl mx-auto py-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Welcome</h2>
      {/* Vectorize Resource button */}
      <div className="mb-4">
        <button
          onClick={handleVectorizeResource}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${loading && 'opacity-50 cursor-not-allowed'}`}
          disabled={loading}
        >
          Vectorize Resource
        </button>
        {loading && <div className="ml-2 inline-block animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>}
      </div>

      {/* Message to display */}
      {showMessage && (
        <div className="bg-yellow-200 text-yellow-800 p-4 mb-4 rounded">
          Some resources may take time to vectorize...
        </div>
      )}

      {/* Button to navigate to Chatbox */}
      <div className="mb-4">
        <button
          onClick={handleOpenChatbox}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Open Chatbox
        </button>
      </div>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="p-6">
          <p className="text-gray-600">Resources in your project</p>
          <Resources projectId={projectId} />
        </div>
      </div>
    </div>
  );
}

export default ProjectPage;
