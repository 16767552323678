import React from 'react';
import WorksBanner from '../assets/4.png'; // Import the PNG file

function Features() {
  return (
    <div className="relative min-h-screen overflow-hidden">
      <div className="absolute inset-0">
        <img src={WorksBanner} alt="Works Banner" className="w-full h-full" />
      </div>
      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold mb-4">How It Works</h1>
        <p className="text-lg mb-4">
          Embedchain simplifies the creation of personalized large language model (LLM) applications by efficiently managing unstructured data. It segments data into manageable sections, generates relevant embeddings, and stores them in a vector database for optimized retrieval. With a diverse set of APIs, users can extract contextual information, find precise answers, or engage in interactive chat conversations tailored to their own data.
        </p>
      </div>
    </div>
  );
}

export default Features;
