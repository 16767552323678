import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resourceTypes } from '../configs/resourceTypes';

const ResourceCard = ({ resource, onClick }) => {
  const cardStyle = {
    backgroundColor: resource.AddedToVectorDB ? '#F0FFF4' : '#FFF5F5', // Greenish background if added to Vector DB, otherwise pinkish
    border: resource.AddedToVectorDB ? '2px solid #38A169' : '2px solid #E53E3E', // Greenish border if added to Vector DB, otherwise pinkish
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  };

  const iconColor = resource.AddedToVectorDB ? '#38A169' : '#E53E3E'; // Greenish icon color if added to Vector DB, otherwise pinkish

  const scrollableNameStyle = {
    maxHeight: '50px', // Adjust the max height as needed
    overflowY: 'auto',
    fontSize: '1.1rem', // Increase font size slightly
    paddingBottom: '10px', // Add padding between name and resource class
  };

  return (
    <div className="mb-4" style={cardStyle} onClick={() => onClick(resource)}>
      <div className="scrollable-name" style={scrollableNameStyle}>
        <h5 className="mb-2 text-lg font-semibold text-gray-900">{resource.FileName}</h5>
        
      </div>
      <p className="mb-2 text-sm text-gray-600">{resource.UploadDate}</p>
      <p className="mb-4 text-sm text-gray-600">Type: {resourceTypes[resource.resource_type_id].name}</p>
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={resourceTypes[resource.resource_type_id].icon}
          className="mr-2 w-5 h-5 text-gray-600"
        />
        <p className="text-sm text-gray-600">{resourceTypes[resource.resource_type_id].name}</p>
      </div>
      <div className="flex items-center mt-2">
        {resource.AddedToVectorDB ? (
          <span className="text-green-500 mr-2">&#10004;</span> // Green check mark if added to Vector DB
        ) : (
          <span className="text-gray-500 mr-2">&#10006;</span> // Gray cross mark if not added to Vector DB
        )}
        <p className="text-sm text-gray-600">
          {resource.AddedToVectorDB ? 'Added to Vector DB' : 'Not added to Vector DB'}
        </p>
      </div>
    </div>
  );
};

export default ResourceCard;
