import React from 'react';
import WorksBanner from '../assets/6.png'; // Import the PNG file

function HowItWorks() {
  return (
    <div className="relative min-h-screen overflow-hidden">
      <div className="absolute inset-0">
        <img src={WorksBanner} alt="Works Banner" className="w-full h-full" />
      </div>
      <div className="container mx-auto py-8 text-center">
        <h1 className="text-4xl font-bold mb-8 text-blue-600">Discover How It Works</h1>
        <p className="text-lg mb-8 text-gray-800 leading-relaxed">
          Simplify the creation of personalized large language model (LLM) applications by efficiently managing unstructured data. Embedchain segments data into manageable sections, generates relevant embeddings, and stores them in a vector database for optimized retrieval. With a diverse set of APIs, users can extract contextual information, find precise answers, or engage in interactive chat conversations tailored to their own data.
        </p>
      </div>
    </div>
  );
}

export default HowItWorks;
