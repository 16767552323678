import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import FeaturesPage from './pages/Features';
import HowItWorks from './pages/HowItWorks';
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import Pricing from './pages/PricingPage';
import HomePage from './pages/HomePage';
import ViewProjects from './pages/ViewProject';
import ProjectPage from './pages/ProjectPage';
import AddResource from './components/AddResource';
import ChatBox from './pages/Chatbox';
import ProtectedRoute from './utils/ProtectedRoute';
import AddModel from './pages/AddModel';
import SingleResource from './pages/SingleResource';
import {useNavigate} from 'react-router-dom';
import DeleteProjects from './pages/DeleteProjects';
import PublishProjects from './pages/PublishProjects';
import PublicChatboxes from './pages/PublicChatboxes';
import DeployedProjects from './pages/DeployedProjects';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPassword from './pages/ResetPassword';


function App() {

  //Check is user is LoggedIn
  const isLoggedIn = localStorage.getItem('isLoggedIn') 

  
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
              {isLoggedIn ? (
                  <Route path="/" element={<ViewProjects />} />
                ) : (
                  <Route path="/" element={<HomePage />} />
                )}
            <Route path="/contact" element={<Contact />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/register" element={<RegistrationPage />} />
            <Route path = "/test/addmodel" element={<AddModel/>}/>
            <Route path = "/about-us" element={<AboutUs/>}/>
            <Route path= "/home" element={<ProtectedRoute> <ViewProjects/> </ProtectedRoute>} />
            <Route path= "/delete" element={<ProtectedRoute> <DeleteProjects/> </ProtectedRoute>} />
            <Route path = "/publish" element={<ProtectedRoute><PublishProjects/> </ProtectedRoute>}/>

            <Route path="/project/:projectId" element={<ProtectedRoute> <ProjectPage /> </ProtectedRoute>} />
            <Route path="/addresource/:projectId" element={<ProtectedRoute> <AddResource /> </ProtectedRoute>} />
            <Route path="/chat/:projectId/:userId" element={<ProtectedRoute> <ChatBox /> </ProtectedRoute>} />
            <Route path="/single_resource/:resourceID" element={<ProtectedRoute> <SingleResource /> </ProtectedRoute>} />
            <Route path="/deployed" element={<ProtectedRoute> <DeployedProjects /> </ProtectedRoute>} />

            <Route path = "/qa/:endpoint" element = {<PublicChatboxes/>}/>
            <Route path = "/forgot-password" element={<ForgotPasswordPage/>}/>
            <Route path="/reset-password" element={<ResetPassword />} />



            



            




            




            {/* You can add more routes here as needed */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
