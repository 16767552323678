import React, { useState, useRef, useEffect } from 'react';
import { API_BASE_URL } from '../configs/config';
import { useParams } from 'react-router-dom';

// Component for system responses
const SystemResponse = ({ content }) => (
  <div className="flex flex-col mb-2">
    {content.split('\n').map((paragraph, index) => (
      <p key={index} className="p-2 rounded-md shadow-sm text-black" style={{ fontFamily: 'Montserrat', fontSize: '20px' }}>{paragraph}</p>
    ))}
  </div>
);

function ChatBox() {
  const { projectId, userId } = useParams();
  const [messages, setMessages] = useState([]); // Initialize messages state as an empty array
  const [newMessage, setNewMessage] = useState(''); // State to store new message input
  const API_URL = API_BASE_URL + '/rag_query/qa'; // API endpoint

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
    // Save messages to local storage whenever it changes
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  // Clear local storage when the component mounts
  useEffect(() => {
    localStorage.removeItem('chatMessages');
  }, []);

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    if (newMessage.trim() !== '') {
      const newMessageObject = {
        content: newMessage.trim(),
        timestamp: new Date().toISOString(),
        fromUser: true, // Indicate that the message is from the user
      };
      setMessages(prevMessages => [...prevMessages, newMessageObject]); // Add user's message to messages state
      setNewMessage('');

      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            project_id: projectId,
            UserID: userId,
            query: newMessage.trim()
          })
        });
        const responseData = await response.json();
        console.log(responseData);
        // Add response message to messages state
        const responseMessageObject = {
          content: responseData.message,
          timestamp: new Date().toISOString(),
          fromUser: false, // Indicate that the message is from the system
        };
        setMessages(prevMessages => [...prevMessages, responseMessageObject]);

        console.log('Message sent successfully');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col bg-white text-black" style={{ fontFamily: 'Montserrat' }}>
      <header className="p-4 bg-gray-800">
        <h1 className="text-3xl font-bold">Chat</h1>
      </header>
      <main className="flex-1 overflow-y-auto p-4">
        <div className="w-3/4 mx-auto">
          {/* Chat messages */}
          {messages.map((message, index) => (
            // Render user message
            message.fromUser ? (
              <div key={index} className="flex flex-col mb-2">
                <p className="p-2 rounded-md shadow-sm bg-gray-700 text-white" style={{ fontSize: '16px' }}>{message.content}</p>
              </div>
            ) : (
              // Render system response
              <SystemResponse key={index} content={message.content} />
            )
          ))}
          <div ref={messagesEndRef} />
        </div>
      </main>
      {/* Message input form */}
      <form onSubmit={handleMessageSubmit} className="p-4 bg-gray-800">
        <div className="flex overflow-y-auto p-8">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="flex-grow py-4 px-10 border rounded-md focus:outline-none bg-gray-200 text-black" // Updated className
            placeholder="Type your message here..."
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none ml-8" // Added margin to the left
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChatBox;
