import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faFileVideo, faFileArchive, faFileWord, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { resourceTypes } from '../configs/resourceTypes'; // Import resource config
import { useSelector } from 'react-redux';
import { API_BASE_URL } from '../configs/config';
import { useNavigate } from 'react-router-dom';

function AddResource() {
  const { projectId } = useParams();
  const [resourceType, setResourceType] = useState('');
  const [file, setFile] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const userId = useSelector(state => state.userId);

  const handleResourceTypeChange = (e) => {
    setResourceType(e.target.value);
    setFile(null);
    setInputValue('');
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // const handleSubmit = async () => {
  //   if (['5', '6'].includes(resourceType) && !file) {
  //     alert('Please select a file.');
  //     return;
  //   }

  //   console.log("Filename/URL:", file ? file.name : inputValue);
  //   console.log("Resource Type:", resourceType);
  //   console.log("UserID:", userId);
  //   console.log("ProjectID:", projectId);

  //   const requestData = {
  //     UserID: userId,
  //     ProjectID: projectId,
  //     FileName: inputValue || file.name,
  //     resource_type_id: resourceType,
  //     AddedToVectorDB: false
  //   };

  //   try {
  //     const response = await fetch(API_BASE_URL + '/resources/add/v2', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(requestData)
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Failed to add resource: ${response.statusText}`);
  //     }

  //     setSuccessMessage('Resource added successfully!');

  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 3000);
  //   } catch (error) {
  //     console.error('Error adding resource:', error.message);
  //   }
  // };
  const handleSubmitv2 = async () => {
    if (['5', '6'].includes(resourceType) && !file) {
      alert('Please select a file.');
      return;
    }

    console.log("Filename/URL:", file ? file.name : inputValue);
    console.log("Resource Type:", resourceType);
    console.log("UserID:", userId);
    console.log("ProjectID:", projectId);

    const formData = new FormData();
    formData.append('UserID', userId);
    formData.append('ProjectID', projectId);
    formData.append('resource_type_id', resourceType);
    formData.append('AddedToVectorDB', false); // Assuming this should always be false
    if (file) {
      formData.append('file', file);
    } else {
      formData.append('FileName', inputValue);
    }

    try {
      const response = await fetch(API_BASE_URL+'/resources/add/v2', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Failed to add resource: ${response.statusText}`);
      }

      setSuccessMessage('Resource added successfully!');

      // Reload the page after 3 seconds
      setTimeout(() => {
        window.location.reload();
      }, 3000);

      // Redirect to some other page after adding resource
      // You can use <Navigate to="/some-page" /> or history.push('/some-page') here

    } catch (error) {
      console.error('Error adding resource:', error.message);
    }
  };

  const getButtonLabel = () => {
    return 'Submit';
  };

  const getInputPlaceholder = () => {
    switch (resourceType) {
      case '2':
        return 'Channel Name';
      case '3':
      case '1':
      case '4':
        return 'URL';
      case '5':
      case '6':
        return 'Upload File';
      default:
        return '';
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-r from-blue-200 via-blue-400 to-blue-600">
      <div className="p-6 max-w-md bg-white rounded-xl shadow-md">
        <h2 className="text-lg font-semibold mb-4">Add a Resource</h2>
        <p className="mb-4">Project ID: {projectId}</p>
        {successMessage && (
          <p className="text-green-500 mb-4">{successMessage}</p>
        )}
        <div className="flex flex-col mb-4">
          <label htmlFor="resourceType" className="mb-1">Select Resource Type</label>
          <div className="relative">
            <select
              id="resourceType"
              className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              value={resourceType}
              onChange={handleResourceTypeChange}
            >
              <option value="">Select Resource Type</option>
              {Object.entries(resourceTypes).map(([id, { name, icon }]) => (
                <option key={id} value={id}>
                  <FontAwesomeIcon icon={icon} className="mr-2" />
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {resourceType && (
          <div>
            {['5', '6'].includes(resourceType) ? (
              <div>
                <label htmlFor="fileUpload" className="mb-1">Upload File</label>
                <input
                  id="fileUpload"
                  type="file"
                  className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none mb-4"
                  onChange={handleFileChange}
                />
              </div>
            ) : (
              <input
                type="text"
                placeholder={getInputPlaceholder()}
                value={inputValue}
                onChange={handleInputChange}
                className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 mb-4"
              />
            )}
            <button onClick={handleSubmitv2} className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-700">
              {getButtonLabel()}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddResource;
