import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from '../configs/config';
import { Link } from 'react-router-dom';

function PublishProjects() {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isPublishing, setIsPublishing] = useState(false); // State to track publish process
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [includeAPIKey, setIncludeAPIKey] = useState(false);
  const [publicDomainName, setPublicDomainName] = useState('');
  const [briefDescription, setBriefDescription] = useState('');
  const userId = useSelector(state => state.userId);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/project/get_unpublished_projects/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch projects');
        }
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    if (userId) {
      fetchProjects();
    }
  }, [userId]);

  const refreshProjects = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/project/get_by_user/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch projects');
      }
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handlePublishConfirmation = (projectId) => {
    setSelectedProjectId(projectId);
    setIsPasswordModalOpen(true);
  };

  const publishProject = async () => {
    try {
      setIsPublishing(true); // Start spinner
  
      const requestBody = {
        UserID: userId,
        ProjectID: selectedProjectId,
        PublicSlug: publicDomainName,
        Description: briefDescription
      };
  
      const response = await fetch(`${API_BASE_URL}/public/publish`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        throw new Error('Failed to publish project');
      }
  
      // Close modal and stop spinner
      setIsPasswordModalOpen(false);
      setIsPublishing(false);
  
      // Refresh projects list after successful publish
      const refreshResponse = await fetch(`${API_BASE_URL}/project/get_by_user/${userId}`);
      if (!refreshResponse.ok) {
        throw new Error('Failed to fetch projects');
      }
      const refreshedData = await refreshResponse.json();
      setProjects(refreshedData);
  
      alert('Project published successfully!');
      console.log(response);
  
    } catch (error) {
      console.error('Error publishing project:', error);
      setIsPublishing(false); // Stop spinner
    }
  };
  

  const handlePasswordSubmit = () => {
    // Password submission logic
    publishProject();
  };

  const filteredProjects = projects.filter(project =>
    project.ProjectName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="max-w-4xl mx-auto py-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Deploy your projects</h2>

      {/* Search Bar */}
      <div className="mb-6 relative">
        <input
          type="text"
          placeholder="Search projects..."
          className="bg-gray-200 border-2 border-gray-300 focus:outline-none focus:border-blue-500 rounded-lg px-4 py-2 w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <svg
          className="absolute right-3 top-3 h-5 w-5 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      </div>

      {/* Projects Grid */}
      <div className="grid md:grid-cols-1 gap-8">
        {filteredProjects.map(project => (
          <div key={project.ProjectID} className="relative bg-white shadow-xl rounded-lg overflow-hidden transition duration-500 hover:shadow-2xl">
            <div className="p-6">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <div className="h-10 w-10 rounded-full bg-green-500 mr-3 flex items-center justify-center">
                    <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-semibold text-gray-900">{project.ProjectName}</h3>
                </div>
                <div>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed mr-2"
                    onClick={() => handlePublishConfirmation(project.ProjectID)}
                    disabled={isPublishing}
                  >
                    {isPublishing ? (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Publishing...
                      </div>
                    ) : (
                      'Publish'
                    )}
                  </button>
                  {/* Here is the end  */}
                </div>
              </div>
              <p className="text-gray-700 my-4">{project.Description}</p>
              <p className="text-gray-600 text-sm">Creation Date: {project.CreationDate}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Password Modal */}
      {isPasswordModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
            <div className="mb-4">
              <h2 className="text-2xl font-bold text-gray-800 mb-2">Confirm Publishing</h2>
              <p className="text-gray-600">Are you sure you want to publish this project?</p>
            </div>
            <form onSubmit={handlePasswordSubmit}>
              <div className="mb-6">
                <label htmlFor="publicDomainName" className="block text-sm font-medium text-gray-700 mb-1">
                  Public Domain Name
                </label>
                <input
                  type="text"
                  id="publicDomainName"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={publicDomainName}
                  onChange={(e) => setPublicDomainName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-6">
                <label htmlFor="briefDescription" className="block text-sm font-medium text-gray-700 mb-1">
                  Brief Description
                </label>
                <textarea
                  id="briefDescription"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                  value={briefDescription}
                  onChange={(e) => setBriefDescription(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-6">
                <label htmlFor="includeAPIKey" className="flex items-center">
                  <input
                    type="checkbox"
                    id="includeAPIKey"
                    className="form-checkbox h-5 w-5 text-blue-500 transition duration-150 ease-in-out"
                    checked={includeAPIKey}
                    onChange={(e) => setIncludeAPIKey(e.target.checked)}
                  />
                  <span className="ml-2 text-gray-700">Include API Master Key</span>
                </label>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 mr-2"
                  onClick={() => setIsPasswordModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Publish
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

    </div>
  );
}

export default PublishProjects;
