import React from 'react';

function PricingPage() {
  return (
    <div className="bg-gradient-to-r from-indigo-600 to-purple-600 min-h-screen">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-white tracking-wide uppercase">Pricing</h2>
          <p className="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">
            Choose a plan that suits your needs
          </p>
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="px-6 py-8">
              <h2 className="text-2xl font-extrabold text-gray-900 text-center">Start</h2>
              <p className="mt-4 text-base text-gray-500 text-center">Start with one project to get organized.</p>
              <div className="mt-6 flex items-center justify-center">
                <span className="text-5xl font-extrabold text-gray-900">FREE</span>
              </div>
            </div>
            <div className="px-6 pt-6 pb-8 bg-gray-50">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <span className="text-green-500 font-semibold">✓</span>
                  </div>
                  <p className="ml-3 text-base text-gray-700">Add up to 10 resources within your project</p>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <span className="text-green-500 font-semibold">✓</span>
                  </div>
                  <p className="ml-3 text-base text-gray-700">Execute up to 100 queries to gather insights</p>
                </li>
              </ul>
              <div className="mt-8">
                <button
                  type="button"
                  className="w-full bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Try for Free
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="px-6 py-8">
              <h2 className="text-2xl font-extrabold text-gray-900 text-center">Grow</h2>
              <p className="mt-4 text-base text-gray-500 text-center">Manage up to 5 different projects simultaneously.</p>
              <div className="mt-6 flex items-center justify-center">
                <span className="text-5xl font-extrabold text-gray-900">$10</span>
                <span className="ml-3 text-xl font-medium text-gray-500">/month</span>
              </div>
            </div>
            <div className="px-6 pt-6 pb-8 bg-gray-50">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <span className="text-green-500 font-semibold">✓</span>
                  </div>
                  <p className="ml-3 text-base text-gray-700">Utilize up to 25 resources per project</p>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <span className="text-green-500 font-semibold">✓</span>
                  </div>
                  <p className="ml-3 text-base text-gray-700">Execute up to 1000 queries for deeper analysis</p>
                </li>
              </ul>
              <div className="mt-8">
                <button
                  type="button"
                  className="w-full bg-white border-2 border-indigo-600 rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="px-6 py-8">
              <h2 className="text-2xl font-extrabold text-gray-900 text-center">Enterprise</h2>
              <p className="mt-4 text-base text-gray-500 text-center">Manage as many projects as you need for your enterprise.</p>
              <div className="mt-6 flex items-center justify-center">
                <span className="text-5xl font-extrabold text-gray-900">$30</span>
                <span className="ml-3 text-xl font-medium text-gray-500">/month</span>
              </div>
            </div>
            <div className="px-6 pt-6 pb-8 bg-gray-50">
              <ul className="space-y-4">
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <span className="text-green-500 font-semibold">✓</span>
                  </div>
                  <p className="ml-3 text-base text-gray-700">Utilize resources without any limitations</p>
                </li>
                <li className="flex items-start">
                  <div className="flex-shrink-0">
                    <span className="text-green-500 font-semibold">✓</span>
                  </div>
                  <p className="ml-3 text-base text-gray-700">Receive 24/7 support for all your needs</p>
                </li>
              </ul>
              <div className="mt-8">
                <button
                  type="button"
                  className="w-full bg-white border-2 border-indigo-600 rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Upgrade
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingPage;