import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"

const ProtectedRoute = ({children}) => {
    const isLoggedIn = localStorage.getItem('isLoggedIn')

    if(!isLoggedIn) {
        return <Navigate to="/login"  />
    }
 return children

};

export default ProtectedRoute;