import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto text-center">
        <p className="text-sm">© 2024 DocuMentor. All rights reserved.</p>
        <div className="mt-4">
          <a href="#" className="inline-block mx-2 hover:text-gray-300">Privacy Policy</a>
          <a href="#" className="inline-block mx-2 hover:text-gray-300">Terms of Service</a>
          <a href="#" className="inline-block mx-2 hover:text-gray-300">Contact Us</a>
        </div>
        <div className="mt-4">
          <a href="#" className="inline-block mx-2 hover:text-gray-300">Facebook</a>
          <a href="#" className="inline-block mx-2 hover:text-gray-300">Twitter</a>
          <a href="#" className="inline-block mx-2 hover:text-gray-300">LinkedIn</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
