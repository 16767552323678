import { LOGIN , LOGOUT } from "./actionTypes";



// Action creators
export const login = (userId) => ({
    type: LOGIN,
    payload: { userId },
  });
  
export const logout = () => ({
    type: LOGOUT,
  });