import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../configs/config';

// Modal component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        {children}
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
        >
          Close
        </button>
      </div>
    </div>
  );
};

// Component for system responses
const SystemResponse = ({ content }) => (
  <div className="flex flex-col mb-2">
    {content.split('\n').map((paragraph, index) => (
      <p key={index} className="p-2 rounded-md shadow-sm text-black" style={{ fontFamily: 'Montserrat', fontSize: '20px' }}>{paragraph}</p>
    ))}
  </div>
);

function PublicChatboxes() {
  const { endpoint } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    localStorage.removeItem('chatMessages');
  }, []);

  const handleMessageSubmit = async (e) => {
    e.preventDefault();
    if (newMessage.trim() !== '') {
      const newMessageObject = {
        content: newMessage.trim(),
        timestamp: new Date().toISOString(),
        fromUser: true,
      };
      setMessages(prevMessages => [...prevMessages, newMessageObject]);
      setNewMessage('');

      try {
        const response = await fetch(API_BASE_URL+'/public_rag_query/qa', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            public_domain_endpoint: endpoint,
            query: newMessage.trim()
          })
        });
        const responseData = await response.json();
        const responseMessageObject = {
          content: responseData.message,
          timestamp: new Date().toISOString(),
          fromUser: false,
        };
        setMessages(prevMessages => [...prevMessages, responseMessageObject]);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col bg-white text-black" style={{ fontFamily: 'Montserrat' }}>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="bg-white p-8 rounded-lg shadow-xl max-w-lg w-full">
          <h2 className="text-3xl font-extrabold mb-6 text-center text-gray-800">
            Welcome to RAGStream's Public Chatbox
          </h2>
          <p className="mb-4 text-center text-gray-600">
            This is a public chatbox where you can interact with our AI system.
          </p>
          <p className="mb-4 font-semibold text-gray-700">Disclaimer:</p>
          <ul className="list-disc pl-5 mb-6 space-y-3 text-gray-600">

            <li>The AI's responses are generated based on available data and may not always be accurate.</li>
            <li>Use the information provided at your own discretion.</li>
          </ul>
        </div>
      </Modal>
      
      <header className="p-4 bg-gray-800">
        <h1 className="text-3xl font-bold text-white">Chat</h1>
      </header>
      <main className="flex-1 overflow-y-auto p-4">
        <div className="w-3/4 mx-auto">
          {messages.map((message, index) => (
            message.fromUser ? (
              <div key={index} className="flex flex-col mb-2">
                <p className="p-2 rounded-md shadow-sm bg-gray-700 text-white" style={{ fontSize: '16px' }}>{message.content}</p>
              </div>
            ) : (
              <SystemResponse key={index} content={message.content} />
            )
          ))}
          <div ref={messagesEndRef} />
        </div>
      </main>
      <form onSubmit={handleMessageSubmit} className="p-4 bg-gray-800">
        <div className="flex">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="flex-grow py-4 px-10 border rounded-md focus:outline-none bg-gray-200 text-black"
            placeholder="Type your message here..."
          />
          <button
            type="submit"
            className="ml-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

export default PublicChatboxes;