import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API_BASE_URL } from '../configs/config';

function DeployedProjects() {
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);
  const userId = useSelector(state => state.userId);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/project/get_published_projects/${userId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch projects');
        }
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    if (userId) {
      fetchProjects();
    }
  }, [userId]);

  const filteredProjects = projects.filter(project =>
    project.ProjectName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openModal = (project) => {
    setSelectedProject(project);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  return (
    <div className="max-w-4xl mx-auto py-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Deployed Projects</h2>

      {/* Search Bar */}
      <div className="mb-6 relative">
        <input
          type="text"
          placeholder="Search projects..."
          className="bg-gray-200 border-2 border-gray-300 focus:outline-none focus:border-blue-500 rounded-lg px-4 py-2 w-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <svg
          className="absolute right-3 top-3 h-5 w-5 text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      </div>

      {/* Projects Grid */}
      <div className="grid md:grid-cols-1 gap-8">
        {filteredProjects.map(project => (
          <div
            key={project.ProjectID}
            className="relative bg-white shadow-xl rounded-lg overflow-hidden transition duration-500 hover:shadow-2xl cursor-pointer"
            onClick={() => openModal(project)}
          >
            <div className="p-6">
              <div className="flex items-center">
                <div className="h-10 w-10 rounded-full bg-green-500 mr-3 flex items-center justify-center">
                  <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <h3 className="text-lg font-semibold text-gray-900">{project.ProjectName}</h3>
              </div>
              <p className="text-gray-700 my-4">{project.Description}</p>
              <p className="text-gray-600 text-sm">Creation Date: {project.CreationDate}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Project Details Modal */}
      {selectedProject && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-2xl w-full">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-2xl font-bold text-gray-800 mb-2">{selectedProject.ProjectName}</h2>
                <p className="text-gray-600 mb-4">Project ID: {selectedProject.ProjectID}</p>
              </div>
              <button
                className="text-gray-500 hover:text-gray-800 focus:outline-none"
                onClick={closeModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="mb-4">
              <p className="text-gray-700">Description: {selectedProject.Description}</p>
              <p className="text-gray-700 mt-2">Public Description: {selectedProject.PublicDescription}</p>
            </div>
            <div className="mb-4">
              <p className="text-gray-700">Public Slug: {selectedProject.PublicSlug}</p>
              <p className="text-gray-700 mt-2">Creation Date: {selectedProject.CreationDate}</p>
            </div>
            <div>
              <p className="text-gray-700">User ID: {selectedProject.UserID}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeployedProjects;