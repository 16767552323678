import { faGlobe, faAddressCard, faFileVideo, faFileArchive, faFileWord, faFilePdf } from '@fortawesome/free-solid-svg-icons';

export const resourceTypes = {
  1: {
    name: 'Web Page',
    icon: faGlobe,
  },
  2: {
    name: 'YouTube Channel',
    icon: faFileVideo,
  },
  3: {
    name: 'YouTube Video',
    icon: faFileVideo,
  },
  4: {
    name: 'Documentation Site',
    icon: faFileArchive,
  },
  5: {
    name: 'DOCX File',
    icon: faFileWord,
  },
  6: {
    name: 'PDF File',
    icon: faFilePdf,
  },
  // Add more resource types here with their corresponding IDs and icons
};
