import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux'; // Import combineReducers from Redux
import authReducer from './store/reducer'; // Import your authReducer
import './index.css';

import App from './App';

// Combine reducers
//const rootReducer = combineReducers({
//  auth: authReducer // Assuming your authReducer manages authentication state
  // Add other reducers here if you have more
//});

// Create Redux store
const store = createStore(authReducer);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
