import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../store/actions';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { isLoggedIn } = useSelector(state => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    window.location.reload();
  };

  return (
    <header className="bg-gray-800">
      <div className="container mx-auto px-4 py-6">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-white text-2xl font-bold tracking-wide">
            RAGStream
          </Link>
          <nav>
            <ul className="flex space-x-8">
              <li>
                <Link to="/features" className="text-gray-300 hover:text-white transition duration-300">
                  Features
                </Link>
              </li>
              <li>
                <Link to="/how-it-works" className="text-gray-300 hover:text-white transition duration-300">
                  How It Works
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="text-gray-300 hover:text-white transition duration-300">
                  Pricing
                </Link>
              </li>
              <li>
                <Link to="/about-us" className="text-gray-300 hover:text-white transition duration-300">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-300 hover:text-white transition duration-300">
                  Contact
                </Link>
              </li>
              <li>
                {isLoggedIn ? (
                  <button
                    onClick={handleLogout}
                    className="text-gray-300 hover:text-white transition duration-300"
                  >
                    Logout
                  </button>
                ) : (
                  <Link to="/login" className="text-gray-300 hover:text-white transition duration-300">
                    Login
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;