import React, { useState } from 'react';


function AddModel() {

    const jwt_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcxMzU2NjI1MCwianRpIjoiNmUyYWFhOGEtNzQ0ZC00MDVjLTllYTMtYjM1ZjZjNzY5YzMyIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6MiwibmJmIjoxNzEzNTY2MjUwLCJjc3JmIjoiNDIyNGQ5MDQtODAxZi00NWNkLTgyZjEtYjg3ZTRiOGVkMGJjIiwiZXhwIjoxNzEzNTY3MTUwfQ.jbGKW3co6tyl7P5GbhzKgsEVxrvJctF3V0uxD7YNjws";
    const name = "TestModel.joblib";
    const description = "Test Model for uploading files from broswer";
    const tags = [1];
    const[FiletoAdd , setFiletoAdd] = useState(null);

    const BASE_URL= "http://3.129.67.70"


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFiletoAdd(file)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("Uploaded file", FiletoAdd);
    
        // Check if a file is selected
        if (!FiletoAdd) {
            console.log("No file selected");
            return;
        }
    
        // Prepare form data
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        tags.forEach(tag => {
            formData.append('tags', tag);
        });
        formData.append('model_file', FiletoAdd);
    
        try {
            // Make the API call
            const response = await fetch(`${BASE_URL}/model/add`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt_token}`,
                },
                body: formData,
            });
    
            // Check if the request was successful
            if (!response.ok) {
                const errorData = await response.json();
                console.error('Failed to upload model:', errorData.error);
                return;
            }
    
            // Model uploaded successfully
            console.log('Model uploaded successfully');
        } catch (error) {
            console.error('Error uploading model:', error);
        }
    };


    return (
      <div className="container mx-auto py-8">
        <h1 className="text-3xl font-bold mb-4">How It Works</h1>
        <p className="text-lg mb-4">
         AddModel
        </p>

        {/* CREATE A UPLOAD MODEL */}

        <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleFileChange}/>
            <button type = "submit"> Upload</button>
        </form>
      </div>
    );
  }
  
  export default AddModel;