import { LOGIN, LOGOUT } from './actionTypes';

// Load initial state from localStorage
const initialState = {
  isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
  userId: localStorage.getItem('userId'),
};

const authReducer = (state = initialState, action) => {
  console.log("Reducer called with action:", action.type);
  console.log("Current state:", state);
  switch (action.type) {
    case LOGIN:
      // Save login state to localStorage
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('userId', action.payload.userId);
      return {
        ...state,
        isLoggedIn: true,
        userId: action.payload.userId,
      };
    case LOGOUT:
      // Clear localStorage on logout
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('userId');
      return {
        ...state,
        isLoggedIn: false,
        userId: null,
      };
    default:
      return state;
  }
};

export default authReducer;
