import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL } from '../configs/config';
import { useNavigate } from 'react-router-dom';
import ResourceCard from '../components/ResourceCard'; // Import the modularized ResourceCard component

const Resources = ({ projectId }) => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.userId);
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchResources = async () => {
      setLoading(true);
      try {
        const response = await fetch(API_BASE_URL + '/resources/get_all', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ UserID: userId, ProjectID: projectId }),
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch resources: ${response.statusText}`);
        }

        const data = await response.json();
        setResources(data || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (userId && projectId) {
      fetchResources();
    }
  }, [userId, projectId, dispatch]);

  const handleAddResourceClick = () => {
    navigate(`/addresource/${projectId}`);
  };

  const handleResourceCardClick = (resource) => {

    console.log(resource)     

    if (resource.resource_type_id === 1 || resource.resource_type_id === 2) {
      window.open(resource.FileName, '_blank'); // Open the URL in a new tab
    }

    if (resource.resource_type_id === 6)
    {
      navigate(`/single_resource/${resource.ResourceID}`); // Navigate to SingleResource with resourceId in the URL
    }
  };

  const filteredResources = resources.filter(resource =>
    resource.FileName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="bg-white rounded-lg shadow-md px-4 py-6">
      <div className="flex justify-between mb-4">
        <h2 className="text-xl font-bold text-gray-800">Resources</h2>
        <button
          onClick={handleAddResourceClick}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
        >
          Add Resource
        </button>
      </div>
      <input
        type="text"
        placeholder="Search by name..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="w-full mb-4 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 shadow-sm transition-shadow duration-300 ease-in-out"
      />
      {loading ? (
        <p className="text-center text-gray-600">Loading resources...</p>
      ) : error ? (
        <p className="text-center text-red-600">Error: {error}</p>
      ) : filteredResources.length > 0 ? (
        <ul className="list-none overflow-y-auto max-h-80">
          {filteredResources.map(resource => (
            <ResourceCard
              key={resource.ResourceID}
              resource={resource}
              onClick={handleResourceCardClick}
            />
          ))}
        </ul>
      ) : (
        <p className="text-center text-gray-600">No resources found.</p>
      )}
    </div>
  );
};

export default Resources;
