import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_BASE_URL } from '../configs/config';
import CustomSpinner from '../assets/Loading.svg';

function AddProject({ onClose, onProjectAdded }) {
  const [newProjectData, setNewProjectData] = useState({
    ProjectName: '',
    Description: ''
  });
  const [addingProject, setAddingProject] = useState(false); // New state for tracking project addition
  const userId = useSelector(state => state.userId);
  const dispatch = useDispatch();

  const handleInputChange = e => {
    const { name, value } = e.target;
    setNewProjectData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleCreateProject = async () => {
    try {
      setAddingProject(true); // Start adding project
      const response = await fetch(`${API_BASE_URL}/project/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ProjectName: newProjectData.ProjectName,
          Description: newProjectData.Description,
          UserID: userId
        })
      });
      if (!response.ok) {
        throw new Error('Failed to create project');
      }
      // Refresh project list after creating new project
      setNewProjectData({ ProjectName: '', Description: '' });
      onClose();
      onProjectAdded(); // Call the onProjectAdded function to refresh projects
    } catch (error) {
      console.error('Error creating project:', error);
    } finally {
      setAddingProject(false); // Stop adding project
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
      <div className="fixed inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
      <div className="bg-white rounded-lg p-8 max-w-md w-full relative">
        <h2 className="text-2xl font-semibold mb-6">Create New Project</h2>
        <div className="mb-4">
          <label htmlFor="projectName" className="block text-gray-700 font-medium mb-2">Project Name</label>
          <input
            type="text"
            id="projectName"
            name="ProjectName"
            value={newProjectData.ProjectName}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md w-full px-4 py-2 focus:outline-none focus:border-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-gray-700 font-medium mb-2">Description</label>
          <textarea
            id="description"
            name="Description"
            value={newProjectData.Description}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-md w-full px-4 py-2 focus:outline-none focus:border-indigo-500"
            rows={4}
          />
        </div>
        <div className="flex justify-end">
          {addingProject ? (
            <div className="flex items-center">
              <img src={CustomSpinner} className="h-6 w-6 mr-3" alt="Custom Spinner" /> {/* Use your custom spinner here */}
              <span>Adding project...</span>
            </div>
          ) : (
            <>
              <button
                className="px-6 py-3 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-4"
                onClick={handleCreateProject}
              >
                Create
              </button>
              <button
                className="px-6 py-3 bg-gray-300 text-gray-700 font-semibold rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                onClick={onClose}
              >
                Cancel
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AddProject;
